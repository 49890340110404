<template>
  <div class="our-services">
    <div class="services-container_grid">
      <div class="services-container">
        <ul class="services_titles__content">
          <li class="title-dev">
            <router-link :to="{ name: 'Development'}">
              <img class="ico-image" src="../../assets/ico-imgs/ico-development.svg" alt="Development">
              Development
            </router-link>
          </li>

          <li class="title-design">
            <router-link :to="{ name: 'Design'}">
              <img class="ico-image" src="../../assets/ico-imgs/ico-design.svg" alt="Design">
              Design
            </router-link>
          </li>

          <li class="title-support">
            <router-link :to="{ name: 'Support'}">
              <img class="ico-image" src="../../assets/ico-imgs/ico-support.svg" alt="Support">
              Support
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services"
}
</script>

<style scoped lang="scss">
.our-services {
  padding: 12vh 0;
}

.services-container_grid {
  max-width: 1400px !important;
  width: 95%;
  margin: 12vh auto;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.services-container {
  position: relative;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-decoration: none;
}

.services_titles__content a {
  font-weight: 900;
  font-size: 3.5vw;
  list-style-type: none;
  line-height: 1.8;
  text-decoration: none;
  display: inline-block;
  color: #1c1c1c;
}

.services_titles__content a:hover {
  transform: translateY(-10px);
  transition: all 0.5s ease;
  -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
  -webkit-mask-size: 200%;
  animation: shine 2s infinite;
}

@-webkit-keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

.title-dev a {
  color: #3098c8;
}

.title-design a {
  color: #bd4057;
}

.title-support a {
  color: #389d38;
}

@media only screen and (max-width: 1440px) {
  .ico-image {
    padding-right: 10px;
    width: 3.5vw;
  }
}

@media only screen and (max-device-width: 768px) {
  .services_titles__content a {
    font-size: 8vw;
  }
  .ico-image {
    width: 6vw;
  }
}

@media only screen and (max-width: 425px) {
  .our-services {
    padding: 5% 0;
  }

  .services-container_grid {
    margin: 0 auto;
  }

  .ico-image {
    width: 8.5vw;
  }
}

@media only screen and (max-width: 330px) {
  .ico-image {
    width: 10vw;
  }
  .services-container_grid {
    margin: 0 auto;
  }
}
</style>
