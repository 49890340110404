<template>
  <section class="content-area">
    <ExpertiseIntro></ExpertiseIntro>
    <Parallax></Parallax>
    <Services></Services>
    <ExpertiseExpanded></ExpertiseExpanded>
  </section>
</template>

<script>
import Services from "./Services";
import ExpertiseExpanded from "./ExpertiseExpanded";
import ExpertiseIntro from "./ExpertiseIntro";
import Parallax from "./Parallax";

export default {
  name: 'Expertise',
  components: {
    Parallax,
    ExpertiseIntro,
    ExpertiseExpanded,
    Services,
  }
}
</script>

<style scoped lang="scss">
.content-area {
  background-color: #fafafa;
  display: block;
}
</style>