<template>
  <div id="div_waves_wrapper" class="waves">
    <svg id="svg_waves" width="100%" :height="svgHeight">
      <defs>
        <path v-for="(wave, i) in waves" :key="i" :id="wave.id" stroke="#2d98c8" stroke-width="1.8" :stroke-opacity="wave.opacity" fill="none" :d="wave.pathData"></path>
      </defs>
      <use v-for="(wave, i) in waves" :key="i" :xlink:href="wave.href" x="0" y="0">
        <animate attributeName="x" from="0" :to="wave.animateToX" :dur="wave.animationDuration" repeatCount="indefinite">
        </animate>
      </use>
    </svg>
  </div>
</template>

<script>
export default {
  name: "ExpertiseStripesWaves",
  data() {
    return {
      el: '#div_waves_wrapper',
      svgWidth: 100,    //  SVG width
      svgHeight: 1000,   //  SVG height
      centreY: 50,       // Y coordinates of vertical centre of SVG
      waves: [],        //Array of waves
      wavesCount: 15    // Number of waves in animation
    }
  },
  methods: {
    rnd: function (min, max) {
      return min + Math.random() * (max - min);
    },
    // randomFrequency: function () {
    //   return 3 / (Math.random(1, 8) + Math.random(3, 8) + Math.random(2, 5));
    // },
    partlyRandomFrequency: function (index) {
      const n = index + 1;
      const min = 0.9 + (n * 0.4);
      const max = 0.5 + (n * 0.4);
      return this.rnd(min, max);
    },
    amplitude: function (frequency) {
      return this.rnd(this.svgHeight / 14, this.svgHeight / 7) / frequency * 3;
    },
    opacity: function (frequency) {
      const wavelength = 0.5 / frequency;
      const wavelengthOpacity = Math.pow(wavelength / 2, 1.5);
      const randomOpacity = this.rnd(0.3, 1);
      const blendedOpacity = (wavelengthOpacity + randomOpacity) / 2;  // average (mean) of both
      return (blendedOpacity).toFixed(2);// Rounded to 2 decimal places
    },
    animateToX: function (frequency) {
      return 0 - (this.svgWidth / frequency);
    },
    randomDuration: function () {
      return Math.floor(this.rnd(8, 16)) + "s"; //random number of seconds between limits that
    },
    cycleSVGPath: function (frequency, amplitude, cycleIndex) {  //run SVG path, defined as two cubic Bezier curves - one that approximates the upward

      const centreY = this.centreY;
      const minY = centreY - amplitude;
      const maxY = centreY + amplitude;

      const wavelength = 1 / frequency; // A wave with f == 4 has wavelength of 0.25 * SVG width
      const wavelengthPixels = wavelength * this.svgWidth; // Wavelength... in pixels
      const cycleStartXPixels = wavelengthPixels * cycleIndex; // start position of this cycle in pixels

      //  X coordinate of a Bezier control handle, in pixels
      const cycleX = fraction => (fraction * wavelengthPixels) + cycleStartXPixels;

      return `C ${cycleX(0.2)} ${minY} ${cycleX(0.3)} ${minY} ${cycleX(0.5)} ${centreY} C ${cycleX(0.7)} ${maxY} ${cycleX(0.8)} ${maxY} ${cycleX(1)} ${centreY}`;
    },
    waveSVGPath: function (frequency) {
      const cycleCount = Math.ceil(frequency) + 12;
      const amplitude = this.amplitude(frequency);

      let cyclesSVGPaths = [];
      for (let cycleIndex = 0; cycleIndex < cycleCount; cycleIndex++) {
        cyclesSVGPaths.push(this.cycleSVGPath(frequency, amplitude, cycleIndex));
      }

      return `M 0 ${this.centreY} ${cyclesSVGPaths.join(" ")}`;
    },
    compileWaves() {

      this.svgWidth = document.documentElement.clientWidth;
      this.centreY = Math.ceil(this.svgHeight / 2);

      this.waves = [];
      let frequency;

      for (let index = 0; index < this.wavesCount; index++) {
        frequency = this.partlyRandomFrequency(index);
        this.waves.push({
          id: `wave_${index}`,
          href: `#wave_${index}`,
          pathData: this.waveSVGPath(frequency),
          opacity: this.opacity(frequency),
          animateToX: this.animateToX(frequency),
          animationDuration: this.randomDuration()
        });
      }
    }
  },
  created() {
    this.compileWaves();
    window.addEventListener("resize", this.compileWaves);
  }
}
</script>

<style scoped lang="scss">

@media only screen and (max-width: 550px) {
  .waves {
    display: none;
  }
}

</style>