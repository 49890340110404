<template>
  <div class="cd-intro-section">
    <div class="container-intro">
      <div class="cd-intro__content">
        <div class="cd-intro-black">What</div>
        <div class="cd-intro-blue">we can do</div>
        <div class="cd-intro-red">
          for <div class="cd-intro-red-you"> you </div>
        </div>

        <div class="containerText_testimonials bounce-up cf">
          <div class="container_testimonials__title">A True Partnership
            <br>for Digital Transformation
            <br>You dream it and we’ll do it!
          </div>
          <div class="container_testimonials__content">
            <p>The Scriptics team will help you grow and protect your business
              by maximizing your online presence with fully integrated services including
              web development, design, programming, software development
              and e-commerce business development.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div :style="{ backgroundImage: 'url('+ RightPic +')'}" class="rightPic"></div>
  </div>
</template>

<script>
import $ from "jquery";
import RightPic from '@/assets/home-web-services.svg'
export default {
  name: "ExpertiseIntro",
  data() {
    return {
      RightPic: RightPic,

    }},
  mounted() {
    function isScrolledIntoViewContainerIntro(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var nav = $('.cd-intro-section');

      if (nav.length) {
        var elemTop = nav.offset().top;
      }

      var elemBottom = elemTop + $(elem).height();
      return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    $(document).on('scroll', function () {
      if (isScrolledIntoViewContainerIntro($('.cd-intro-section'))) {
        // console.log('see container');

        const element1 = document.querySelector(".cd-intro-black");
        element1.classList.add("animated", "slideInLeft");

        const element2 = document.querySelector(".cd-intro-blue");
        element2.classList.add("animated", "slideInRight");

        const element3 = document.querySelector(".cd-intro-red");
        element3.classList.add("animated", "zoomIn");
      }
    });
    var $animation_elements = $('.containerText_testimonials');
    var $window = $(window);

    function check_if_in_view() {
      var window_height = $window.height();
      var window_top_position = $window.scrollTop();
      var window_bottom_position = (window_top_position + window_height);

      $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
          $element.addClass('in-view');
        } else {
          $element.removeClass('in-view');
        }
      });
    }

    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');

    $(document).ready(function ($) {
      var ns_in_viewport = function () {
        $('section.content-row').each(function () {
          $(this).isInViewport({tolerance: -100}).addClass('in-view');
        });
      }
      ns_in_viewport();

      $(window).scroll(ns_in_viewport);
    });
  }
}

</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@800&display=swap');
.rightPic{
  width: 50%;
  height: 66vh;
  float: left;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.cd-intro-section {
  padding: 10% 0;
  overflow: hidden;
}
.container-intro {
  max-width: 1400px;
  float: left;
  width: 50%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.cd-intro__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 10%;
}

.cd-intro-black {
  color: #3a3a3a;
}

.cd-intro-blue {
  color: #2d98c8;
}

.cd-intro-red {
  color: #ba3953;
}

.cd-intro-red,
.cd-intro-black,
.cd-intro-blue {
  display: inline-flex;
  white-space: nowrap;
  line-height: .8;
  font-weight: 400;
  font-size: 130px;
  padding-top: 25px;
  font-style: normal;
  hyphens: none;
  gap: 25px;
}

.cd-intro-red-you{
  font-weight: 800;
  font-size: 150px;
}

.containerText_testimonials {
  word-spacing: 1px;
  line-height: 1.4;
  padding-top: 45px;
  text-align: end;
}

.bounce-up {
  opacity: 0;
  -moz-transition: all 700ms ease-out;
  -webkit-transition: all 700ms ease-out;
  -o-transition: all 700ms ease-out;
  transition: all 700ms ease-out;
  -moz-transform: translate3d(0px, 200px, 0px);
  -webkit-transform: translate3d(0px, 200px, 0px);
  -o-transform: translate(0px, 200px);
  -ms-transform: translate(0px, 200px);
  transform: translate3d(0px, 200, 0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bounce-up.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.container_testimonials__title {
  color: #2d98c8;
  font-size: 26pt;
  font-weight: 900;
  line-height: 1;
  word-spacing: 1px;
}

.container_testimonials__content {
  color: #858585;
  line-height: 1.4em;
  padding-top: 10px;
  font-size: 16pt;
}
@media only screen and (max-width: 1440px) {
  .cd-intro-blue {
    letter-spacing: -.02em;
  }
}
@media only screen and (max-width: 1024px) {

  .cd-intro-red,
  .cd-intro-black,
  .cd-intro-blue {
    font-size: 90px;
  }

  .cd-intro-red-you{
    font-size: 125px;
  }

}
@media only screen and (max-width: 768px) {
  .rightPic{
    display: none;
  }
  .container-intro{
    width: 100%;
  }
  .cd-intro-red,
  .cd-intro-black,
  .cd-intro-blue {
    font-size: 100px;
  }
  .cd-intro-red-you{
    font-size: 120px;
  }
  .container_testimonials__title {
    font-size: 24pt;
  }
  .container_testimonials__content {
    font-size: 13pt;
  }
}
@media only  screen and (max-width: 500px) {
  .cd-intro-red, .cd-intro-black, .cd-intro-blue {
    font-size: 80px;
  }
  .containerText_testimonials {
    text-align: start;
    padding-left: 20px;
  }
  .container_testimonials__title {
    font-size: 21pt;
  }
  .container_testimonials__content {
    font-size: 21pt;
    text-align: left;
  }
}
@media only screen and (max-width: 425px) {
  .cd-intro-section {
    padding: 0 0 15% 0;
  }
  .cd-intro-red {
    gap: 15px;
  }
  .container_testimonials__title {
    font-size: 17pt;
  }
  .container_testimonials__content {
    font-size: 15pt;
  }
  .cd-intro-red-you {
    font-size: 90px;
  }
}
@media only screen and (max-width: 375px) {
  .cd-intro-red, .cd-intro-black, .cd-intro-blue {
    font-size: 75px;
  }
  .container_testimonials__title {
    font-size: 15pt;
  }
  .cd-intro-section {
    padding: 0 0 15% 0;
  }
}
@media only screen and (max-width: 330px) {
  .cd-intro-red, .cd-intro-black, .cd-intro-blue {
    font-size: 60px;
  }
  .cd-intro-red-you {
    font-size: 80px;
  }
  .container_testimonials__title,
  .container_testimonials__content {
    font-size: 13pt;
  }
}
</style>
