<template>
  <div :style="{  transform: `translate3d(0, ${offset}px, 0)`, }" class="ParallaxElement">
    <slot/>
  </div>
</template>

<script>
export default {
  name: `ParallaxElement`,
  inject: [`parallaxContainer`],
  props: {
    factor: {
      default: 0.30,
      type: Number,
    },
  },
  computed: {
    offset() {
      const {height, scrollFactor} = this.parallaxContainer;
      return scrollFactor * height * this.factor;
    },
  },
};
</script>