<template>
  <ParallaxContainer class="hero-stripes-waves">
    <ParallaxElement :factor="0.30" class="parallax-element-bg-1">
      <div id="bg-1-2">Docker</div>
      <div id="bg-1-1">Swift</div>
      <div id="bg-1-3">Kotlin</div>
      <div id="bg-1-4">Vue</div>
      <div id="bg-1-5">Angular</div>
    </ParallaxElement>

    <div class="parallax-stripes">
      <ExpertiseStripesWaves></ExpertiseStripesWaves>
    </div>

    <ParallaxElement :factor="0.6" class="parallax-element-bg-2">
      <div id="bg-2-1">Python</div>
      <div id="bg-2-2">PHP</div>
      <div id="bg-2-3">Terraform</div>
      <div id="bg-2-4">Ansible</div>
      <div id="bg-2-5">C#</div>
    </ParallaxElement>

  </ParallaxContainer>
</template>

<script>
import ParallaxContainer from '../../components/Home/ParallaxContainer';
import ParallaxElement from '../../components/Home/ParallaxElement.vue';
import ExpertiseStripesWaves from "./ExpertiseStripesWaves";
import $ from "jquery";

export default {

  name: "Parallax",

  components: {
    ExpertiseStripesWaves,
    ParallaxContainer,
    ParallaxElement
  },

  mounted() {

    const stripesSectionYCoord = $('.parallax-stripes').offset().top;
    const expertiseSectionYCoord = $('.grid-container-fromLeft').offset().top;

    $(window).scroll(function () {

      function elementScrolled() {

        let docViewTop = $(window).scrollTop();

        // don't display the stripes section when the user passes to the next section
        if (docViewTop > expertiseSectionYCoord) {
          $('.parallax-stripes').css('display', 'none');
        } else {
          $('.parallax-stripes').css('display', 'block');
        }

        return (docViewTop > stripesSectionYCoord);

      }

      // make the stripes section fixed, when it comes into view
      if (elementScrolled()) {
        if (!$('.parallax-stripes').hasClass('fixed-stripes')) {
          $('.parallax-stripes').addClass('fixed-stripes');
        }
      } else {
        if ($('.parallax-stripes').hasClass('fixed-stripes')) {
          $('.parallax-stripes').removeClass('fixed-stripes');
        }
      }

    });

  },

}

</script>

<style scoped lang="scss">
$text-color: rgb(12, 31, 50);
$font: "Heebo", sans-serif;

.fixed-stripes {
  position: fixed;
  top: 0;
  width: 100%;
}

#bg-1-1, #bg-1-2, #bg-1-3, #bg-1-4, #bg-1-5, #bg-2-1, #bg-2-2, #bg-2-3, #bg-2-4, #bg-2-5 {
  line-height: 1.3em;
  font-weight: 900;
  color: rgb(12, 31, 50);
  font-family: "Heebo", sans-serif;
}

 #bg-2-4, #bg-1-1, #bg-2-3 {
  font-size: 1.8em;
  font-weight: 900;
  color: rgb(12, 31, 50);
  font-family: "Heebo", sans-serif;
}

#bg-2-1, #bg-2-2, #bg-2-3, #bg-2-4, #bg-2-5{
  background-color: rgb(12, 31, 50);
  background-image: linear-gradient(150deg, #0c1f32 42%, #9319ac 56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  font-family: "Heebo", sans-serif;
}

#bg-2-5, #bg-1-2, #bg-1-4, #bg-1-5, #bg-2-2, #bg-2-1, #bg-1-3  {
  font-size: 4em;
}


#bg-1-2, #bg-2-2 {
  font-size: 60pt;
}

.hero-stripes-waves {
  position: relative;
  max-height: 100vh;
  padding: 12vh 0;
  overflow: hidden;
  height: 100vh;

  .parallax-element-bg-1 {
    position: absolute;
    width: 50%;
    top: 30%;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    z-index: 2;
    color: $text-color;
    font-family: $font;
    line-height: 1.3em;
    font-weight: 900;

  }

  .parallax-element-bg-2 {
    position: absolute;
    width: 60%;
    text-align: center;
    left: 39%;
    top: 20%;
    z-index: 2;
    color: #0c1f32;
    font-family: "Heebo", sans-serif;
    line-height: 1.3em;
    font-weight: 900;
  }

  #bg-1-1 {
    margin-left: -65%;
    margin-top: 16%;
  }

  #bg-1-2 {
    margin-left: -60%;
    margin-top: -16%;

  }

  #bg-1-3 {
    margin-left: 40%;
  }

  #bg-1-4 {
    margin-left: 80%;
    margin-top: -27%;
  }

  #bg-1-5 {
    margin-left: -34%;
    margin-top: 20%;
  }

  #bg-2-1 {
    margin-left: -27%;
    margin-top: 17%;
  }

  #bg-2-2 {
    margin-top: -40%;
    margin-left: 64%;
  }

  #bg-2-3 {
    margin-top: 9%
  }

  #bg-2-5 {
    margin-top: -16%;
    margin-left: 82%;
  }

  #bg-2-4 {
    margin-top: 20%;
    margin-left: 45%;
  }

}


@media only screen and (max-width: 1200px) {
  .hero-stripes-waves {
    #bg-2-3 {
      margin-top: 0;
    }
    #bg-2-1 {
      margin-left: -55%;
      margin-top: 25%;
    }
    #bg-1-2{
      margin-left: -26%;
      margin-top: -16%;
    }
   #bg-1-3 {
      margin-top: -5%;
      margin-left: 23%;
    }
  }
}

@media only screen and (max-width: 990px) {
  #bg-2-3, #bg-2-4 {
    font-size: 1em;
  }
  #bg-1-4, #bg-1-5, #bg-1-3, #bg-2-5, #bg-2-1, #bg-2-2, #bg-1-2 {
    font-size: 2.4em;
  }

}

@media only screen and (max-width: 550px) {
  .hero-stripes-waves {
    position: relative;
    background: url("../../assets/stripes.svg") no-repeat;
    background-position: 50%;
    background-size: cover;
    overflow: hidden;

    #bg-1-2 {
      display: none;
    }

    #bg-2-3 {
      display: none;
    }
    #bg-1-1 {
      margin-left: -63%;
      margin-top: 0;
    }
    #bg-1-5{
      margin-left: -40%;
      margin-top: 67%;
    }
    #bg-1-4 {
      margin-left: 80%;
      margin-top: -68%;
    }
    #bg-2-1{
      margin-left: -14%;
      margin-top: 25%;
    }
    #bg-2-2 {
      margin-top: -77%;
      margin-left: 34%;
    }
    #bg-2-4 {
      margin-top: 26%;
      margin-left: 10%;
    }
    #bg-2-5 {
      margin-top: -26%;
    }
  }
}

@media only screen and (max-width: 425px) {
  #bg-2-4 {
    font-size: .8em;
  }
  #bg-1-1 {
    font-size: 1em;
  }
  #bg-1-4, #bg-1-5, #bg-1-3, #bg-2-5, #bg-2-1, #bg-2-2 {
    font-size: 1.8em;
  }

  .fixed-stripes {
    position: relative;
  }

}

@media only screen and (max-width: 320px) {
  #bg-1-4, #bg-1-5, #bg-1-3, #bg-2-5, #bg-2-1, #bg-2-2 {
    font-size: 1.2em;
  }
}
</style>

